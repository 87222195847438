import * as React from "react";
import { Link } from "gatsby";

import Bridge from "../../components/Bridge.js";
// import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Img from "gatsby-image";
import Layout from "../../components/Layout";
import PreChorus from "../../components/PreChorus.js";
import Verse from "../../components/Verse.js";
import { graphql } from "gatsby";

export default function Californication({ data }) {
  return (
    <Layout>
      <Helmet title="Californication | Red Hot Chili Peppers" />

      <h1>Californication</h1>

      <h2>Red Hot Chili Peppers</h2>

      <h4>Key: Am</h4>

      <h4>Tempo: 92</h4>

      <Link to="/">List</Link>

      <Img fluid={data.file.childImageSharp.fluid} />

      <Columns columns="2">
        <Verse>
          <p>Psychic spies from China try to steal your mind's elation</p>
          <p>And little girls from Sweden dream of silver screen quotation</p>
          <p>And if you want these kind of dreams it's Californication</p>
        </Verse>
        <Verse>
          <p>It's the edge of the world and all of Western civilization</p>
          <p>The sun may rise in the East at least it's settled in a final location</p>
          <p>It's understood that Hollywood sells Californication</p>
        </Verse>
        <PreChorus>
          <p>Pay your surgeon very well to break the spell of aging</p>
          <p>Celebrity skin, is this your chin, or is that war you're waging?</p>
          <p>First born unicorn</p>
          <p>Hardcore soft porn</p>
        </PreChorus>
        <Chorus>
          <p>Dream of Californication</p>
          <p>Dream of Californication</p>
          <p>Dream of Californication</p>
          <p>Dream of Californication</p>
        </Chorus>
        <Verse>
          <p>Marry me, girl, be my fairy to the world, be my very own constellation</p>
          <p>A teenage bride with a baby inside getting high on information</p>
          <p>And buy me a star on the boulevard, it's Californication</p>
        </Verse>
        <Verse>
          <p>Space may be the final frontier but it's made in a Hollywood basement</p>
          <p>And Cobain can you hear the spheres singing songs off Station To Station?</p>
          <p>And Alderaan's not far away, it's Californication</p>
        </Verse>
        <PreChorus>
          <p>Born and raised by those who praise control of population</p>
          <p>Well, everybody's been there and I don't mean on vacation</p>
          <p>First born unicorn</p>
          <p>Hardcore soft porn</p>
        </PreChorus>
        <Chorus>
          <p>Dream of Californication</p>
          <p>Dream of Californication</p>
          <p>Dream of Californication</p>
          <p>Dream of Californication</p>
        </Chorus>
        <Bridge>
          <p>CHORDS</p>
        </Bridge>
        <Verse>
          <p>Destruction leads to a very rough road but it also breeds creation</p>
          <p>And earthquakes are to a girl's guitar, they're just another good vibration</p>
          <p>And tidal waves couldn't save the world from Californication</p>
        </Verse>
        <PreChorus>
          <p>Pay your surgeon very well to break the spell of aging</p>
          <p>Celebrity skin, is this your chin, or is that war you're waging?</p>
          <p>First born unicorn</p>
          <p>Hardcore soft porn</p>
        </PreChorus>
        <Chorus>
          <p>Dream of Californication</p>
          <p>Dream of Californication</p>
          <p>Dream of Californication</p>
          <p>Dream of Californication</p>
        </Chorus>
      </Columns>
    </Layout>
  );
}

export const query = graphql`
  query {
    file(relativePath: { eq: "californication.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`;
